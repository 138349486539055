import React, { createContext, useContext, useState, useEffect } from 'react';
import indexedDBService from '../services/projects-db';
import { type ProjectInfo } from '../modules/spproject/types';

interface ProjectProviderProps {
  children: React.ReactNode;
}

interface ProjectContextProps {
  projects: ProjectInfo[];
  isLoading: boolean;

  deleteProject: (fileUrl: string) => void;

  addProject: (file: File, previewImageBase64: string) => void;
  clearAllProjects: () => void;

  // this is for disable cache for an entirely.
  cacheable: boolean;
  setCacheable: (value: boolean) => void;

  search: string;
  setSearch: (value: string) => void;

  isGridLayout: boolean;
  setIsGridLayout: (value: boolean) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [projects, setProjects] = useState<ProjectInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>(null);

  const [isGridLayout, setIsGridLayout] = useState<boolean>(() => {
    const storedIsGrid = localStorage.getItem('isGrid');
    return storedIsGrid ? JSON.parse(storedIsGrid) : true;
  });

  const [cacheable, setCacheable] = useState<boolean>(() => {
    const storedCacheable = localStorage.getItem('cacheable');
    return storedCacheable ? JSON.parse(storedCacheable) : false;
  });


  useEffect(() => {
    indexedDBService.openDB().then(() => {
      indexedDBService.getProjects().then((storedProjects: ProjectInfo[]) => {
        setProjects(storedProjects);
        setIsLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('cacheable', JSON.stringify(cacheable));
  }, [cacheable]);

  useEffect(() => {
    localStorage.setItem('isGrid', JSON.stringify(isGridLayout));
  }, [isGridLayout]);

  const addProject = (file: File, previewImageBase64: string) => {
    const newProject: ProjectInfo = {
      name: file.name,
      date: new Date().toLocaleDateString(),
      previewImageBase64,
      fileUrl: URL.createObjectURL(file),
      fileSize: file.size,
      file: file,
    };

    const existingProjectIndex = projects.findIndex((project) => project.name === newProject.name);

    if (existingProjectIndex !== -1) {
      const existingProject = projects[existingProjectIndex];
      if (existingProject.file.lastModified !== file.lastModified) {
        setProjects((prevProjects) => {
          const updatedProjects = [...prevProjects];
          updatedProjects[existingProjectIndex] = newProject;
          return updatedProjects;
        });
        indexedDBService.updateProject(newProject);
      } else {
        console.log('This project comes from cache.');
      }
    } else {
      setProjects((prevProjects) => [...prevProjects, newProject]);
      indexedDBService.addProject(newProject);
    }
  };
  const deleteProject = (fileUrl: string) => {
    const projectIndex = projects.findIndex((project) => project.fileUrl === fileUrl);
    if (projectIndex !== -1) {
      const updatedProjects = [...projects];
      updatedProjects.splice(projectIndex, 1);
      setProjects(updatedProjects);
      indexedDBService.deleteProject(fileUrl);
    }
  };
  const clearProjects = () => {
    setProjects([]);
    indexedDBService.clearAllProjects();
  };

  return (
    <ProjectContext.Provider value={{ isGridLayout, setIsGridLayout, cacheable, setCacheable, deleteProject, search, setSearch, projects, isLoading, addProject, clearAllProjects: clearProjects }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
};
