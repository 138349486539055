// RootProvider.js
import React from 'react';
import { CameraProvider } from './camera';
import { ModalProvider } from './modal';
import { SPProjectProvider } from './spproject';
import { FileProvider } from './file';
import { ProjectProvider } from './projects';
import { BrowserRouter } from 'react-router-dom';
import { MeasurementProvider } from './measurement-provider';
import { AlertProvider } from './alert';


const RootProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BrowserRouter>
    <ModalProvider>
      <FileProvider>
        <ProjectProvider>
          <AlertProvider>
            <SPProjectProvider>
              <CameraProvider>
                <MeasurementProvider>
                  {children}
                </MeasurementProvider>
              </CameraProvider>
            </SPProjectProvider>
          </AlertProvider>
        </ProjectProvider>
      </FileProvider>
    </ModalProvider>
  </BrowserRouter>
);

export default RootProvider;
