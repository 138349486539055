import React, { type ReactNode, useEffect, useState } from 'react';
import * as THREE from 'three';
import Constant from '../modules/spproject/constant';
import { type InspectionPointsProps, type InspectionTypes } from '../modules/spproject/types';
import { useModal } from '../providers/modal';
import { useSPProject } from '../providers/spproject';

const InspectionPoints: React.FC<InspectionPointsProps> = ({ inspection }) => {
  const [primitiveList, setPrimitiveList] = useState<ReactNode[]>([]);
  const { openInspectionDetailModal } = useModal();
  const { setSelectedPOI } = useSPProject();

  useEffect(() => {
    setPrimitiveList([]);

    const labelList: THREE.Sprite[] = [];

    if (!inspection?.pointOfInterests?.length) return;

    const colors: Record<InspectionTypes.NoticeType, string> = {
      NotChecked: '#808080',
      Ok: '#087f23',
      Warning: '#ffbb00',
      Error: '#ba000d',
    };

    for (const note of inspection?.pointOfInterests ?? []) {
      const text: string = note.order.toString();

      const color: string = colors[note?.steps?.[note?.stepIndex ?? 0]?.noticeType];
      const canvas = createLabelCanvas(text, color);

      const texture = new THREE.CanvasTexture(canvas);

      const labelMaterial = new THREE.SpriteMaterial({
        map: texture,
        transparent: true,
        depthTest: false,
        depthWrite: false,
        sizeAttenuation: false,
      });

      const label = new THREE.Sprite(labelMaterial);
      label.position.copy(note.position);

      transformPosition(label.position);
      label.position.y += 0.02;

      label.scale.set(0.05, 0.05, 0.05);

      label.userData = {
        tag: Constant.ObjectTag.label,
        id: note.id,
        poi: note
      };

      labelList.push(label);
    }

    setPrimitiveList(
      labelList.map((label) => (
        <primitive
          object={label}
          key={labelList.indexOf(label)}
          onClick={(e: unknown) => {
            setSelectedPOI(e['eventObject']['userData']['poi']);
            openInspectionDetailModal();
          }}
        />
      ))
    );
  }, [inspection]);

  return <group>{primitiveList}</group>;
};

function createLabelCanvas(text: string, color = '#808080'): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.width = 300;
  canvas.height = 300;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.beginPath();
    ctx.arc(150, 150, 150, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.stroke();

    ctx.font = 'bold 200px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'white';
    ctx.fillText(text, 150, 150);
  }

  return canvas;
}


export function transformPosition(p: THREE.Vector3): THREE.Vector3 {
  p.x = -p.x;
  return p;
}

export default InspectionPoints;
