import { DashboardContainer } from './dashboard.styled';

import DashboardRightSide from './right-side/right-side';
import DashboardLeftSide from './left-side/left-side';


const DashboardPage: React.FC = () => (
  <DashboardContainer>
    <DashboardLeftSide />
    <DashboardRightSide />
  </DashboardContainer>
);

export default DashboardPage;