import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { LineAwesome } from 'react-line-awesome-svg';

export const DeleteProjectIcon = styled(LineAwesome)`
  display: none;
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 28px;
  fill:white;
`;

export const ProjectCardContainer = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  margin: 10px;
  overflow: hidden;
  background-color: #2c2c2c;
  border: 1px solid #424242;
  cursor: pointer;
  margin-bottom: 18px !important;
  position: relative;

  &:hover ${DeleteProjectIcon} {
    display: block;
  }
`;

export const NoImageContainer = styled.div`
  height: 200px;
  background-color: #616161;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 65px;
`;


export const ProjectCardImg = styled(Card.Img)`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

export const ProjectCardBody = styled(Card.Body)`
  height: 100px;
`;

export const ProjectCardTitle = styled(Card.Title)`
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
