import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import Helper from '../../modules/helper';
import { MeasurementColor, useMeasurement } from '../../providers/measurement-provider';
import { useModal } from '../../providers/modal';

const MeasurementColorModal = () => {
  const { isMeasurementColorModalOpen, closeMeasurementColorModal } = useModal();
  const { measurementColor, setMeasurementColor } = useMeasurement();

  const handleClose = () => closeMeasurementColorModal();

  const handleColorChange = (event: SelectChangeEvent<MeasurementColor>) => {
    setMeasurementColor(event.target.value as MeasurementColor);
  };

  return (
    <Dialog
      open={isMeasurementColorModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Pick Measurement Color</DialogTitle>
      <DialogContent style={{ width: 260 }}>
        <FormControl fullWidth style={{ marginTop: 18 }}>
          <InputLabel id="color-select-label">Color</InputLabel>
          <Select
            labelId="color-select-label"
            id="color-select"
            value={measurementColor}
            label="Color"
            onChange={handleColorChange}
          >
            {Object.keys(MeasurementColor).filter(key => isNaN(Number(key))).map((colorKey) => (
              <MenuItem key={colorKey} value={MeasurementColor[colorKey]}>
                {Helper.capitalizeFirstLetter(colorKey)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Kapat</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeasurementColorModal;
