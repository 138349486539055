import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

interface GridWithLabelsProps {
  model: THREE.Object3D<THREE.Object3DEventMap>;
}

const GridWithLabels: React.FC<GridWithLabelsProps> = ({ model }) => {
  const { size } = useThree();
  const [grid, setGrid] = useState<THREE.Object3D<THREE.Object3DEventMap> | undefined>();

  useEffect(() => {

    const boundingBox = new THREE.Box3().setFromObject(model);
    const size = boundingBox.getSize(new THREE.Vector3());
    const center = boundingBox.getCenter(new THREE.Vector3());

    const gridObject = new THREE.Object3D();

    const config = {
      linesWidth: 15,
      color: 0xffffff,
      fontSize: size.x / 20,
      fontColor: 0xffffff,
    };

    let { x: width, z: depth } = size;
    const maxDimension = Math.max(width, depth);

    const offset = maxDimension / config.linesWidth;
    const widthOffset = offset * (width / maxDimension);
    const depthOffset = offset * (depth / maxDimension);

    width += widthOffset;
    depth += depthOffset;

    const maxDimensionCalculated = Math.max(width, depth);

    const gridHelper = new THREE.GridHelper(maxDimensionCalculated, config.linesWidth, config.color, config.color);
    gridHelper.position.set(center.x, boundingBox.min.y - 0.001, center.z);

    gridObject.add(gridHelper);

    setGrid(gridObject);
  }, [model, size]);

  return grid ? <primitive object={grid} /> : null;
};

export default GridWithLabels;
