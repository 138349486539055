import React, { useRef } from 'react';
import logo from '../../../assets/supar_white.png';
import { useFileContext } from '../../../providers/file';

import { useLocation, useNavigate } from 'react-router-dom';
import { StyledDashboardLeftSide, StyledDashboardLeftSideMenu, StyledLogo, StyledMenuButton } from '../dashboard.styled';
import { LineAwesome, SvgCogSolid, SvgHomeSolid, SvgUploadSolid } from 'react-line-awesome-svg';

const DashboardLeftSide: React.FC = () => {
  const { setSelectedFile } = useFileContext();

  const fileRef = useRef<File | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      fileRef.current = file;

      setSelectedFile(fileRef.current);
      const fileInput = document.getElementById('file-input') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  };

  return (
    <StyledDashboardLeftSide>
      <StyledLogo>
        <img className="mx-2" src={logo} width="48" alt="Logo" />
      </StyledLogo>
      <StyledDashboardLeftSideMenu>
        <input type="file" id="file-input" accept=".spproject" onChange={handleFileChange} style={{ display: 'none' }} />
        {location.pathname === '/settings' ?
          <StyledMenuButton onClick={() => { navigate('/'); }}>
            <LineAwesome icon={SvgHomeSolid} />
          </StyledMenuButton> :
          <StyledMenuButton htmlFor="file-input">
            <LineAwesome icon={SvgUploadSolid} />
          </StyledMenuButton>}
        <StyledMenuButton onClick={() => { navigate('/settings'); }}>
          <LineAwesome icon={SvgCogSolid} />
        </StyledMenuButton>
      </StyledDashboardLeftSideMenu>
    </StyledDashboardLeftSide>
  );
};

export default DashboardLeftSide;
