import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const SettingsContent = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #262626;
  color: white;
  display: flex;
`;

export const SettingsRightSide = styled.div`
 width: calc(100% - 100px);
 overflow-y: auto;
 overflow-x: hidden;
`;

export const SettingsTopSide = styled.div`
 height: 100px;
 width: 100%;
 border-bottom: 1px solid #525252;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding-right: 26px;
`;

export const SettingsCard = styled(Card)`
  background-color: #2c2c2c;
  border: 1px solid #424242;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 18px !important;
  overflow: hidden;
  width: 100%;

 && .card-body{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
`;
