import React from 'react';

import Moment from 'react-moment';
import DescriptionIcon from '@mui/icons-material/Description';
import { useSPProject } from '../../../providers/spproject';
import { useModal } from '../../../providers/modal';
import { Alert, Avatar, Dialog, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { blue } from '@mui/material/colors';
import { StyledCloseButton, StyledDialogTitle, StyledModalHeader } from './inspection-list.styled';
import { LineAwesome, SvgTimesSolid } from 'react-line-awesome-svg';



const InspectionListModal: React.FC = () => {
  const { inspections, setSelectedInspection } = useSPProject();
  const { isInspectionListModalOpen, closeInspectionListModal } = useModal();

  return (
    <Dialog onClose={() => closeInspectionListModal()} open={isInspectionListModalOpen}>
      <StyledDialogTitle>
        <StyledModalHeader className="modal-header">
          <div className="modal-title h4" style={{ lineHeight: '2 !important' }} >INSPECTIONS</div>
          <StyledCloseButton type="button" className="close" onClick={() => { closeInspectionListModal(); }} >
            <LineAwesome icon={SvgTimesSolid} fill='white' />
          </StyledCloseButton>
        </StyledModalHeader>
      </StyledDialogTitle>
      {inspections == null || inspections.length === 0 ? (
        <Alert severity="info" style={{ margin: '4px 18px ' }}>Can&apos;t find any inspections in this spproject file.</Alert>
      ) : (
        <List sx={{ pt: 0 }}>
          {inspections?.map((inspection) => (
            <ListItem disableGutters key={inspection.name} onClick={() => { setSelectedInspection(inspection); closeInspectionListModal(); }}>
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={inspection.name} secondary={<Moment>{inspection.dateCreated}</Moment>} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  );
};

export default InspectionListModal;
