import styled from 'styled-components';

export const NavHelper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 10;
  border: 1px solid #404040;
  width: 65px;
  border-radius: 4px;
  height: 230px;
  background-color: rgb(50 50 50) !important;

  &&{
    color: white !important;
  }
`;

export const NavHelperItem = styled.a`
  display: inline-flex;
  padding: 12px 18px 12px 18px;
  text-decoration: none;
`;

export const NavHelperImage = styled.img`
  width: 22px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
`;

export const SubMenuMeasurement = styled.div`
  position: absolute;
  right: 75px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 10;
  border: 1px solid #404040;
  width: 65px;
  border-radius: 4px;
  height: 140px;
  background-color: rgb(50, 50, 50) !important;
  top: 0;
  transition: transform 0.2s;

   i,svg {
    transition: transform 0.2s;
    font-size: 22px !important;
    fill: white;
    color: white;

    &:hover {
      transform: scale(1.2);
    }
  }
`;