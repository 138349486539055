import styled from 'styled-components';
import { DialogTitle } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)`
  && {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
  }
`;

export const StyledModalHeader = styled.div`
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
  && {
    line-height: 2 !important;
  }
`;

export const StyledCloseButton = styled.button`
  && {
    color: white;
    font-size: 30px;
  }
`;
