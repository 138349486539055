import React, { createContext, useContext, useState, type ReactNode } from 'react';
import type * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import PasswordDialog from '../components/dialogs/password-dialog';
import SPProject from '../modules/spproject/spproject';
import { type InspectionTypes, type ManifestTypes } from '../modules/spproject/types';
import { useProjects } from './projects';
import { useAlertContext } from './alert';
import { useNavigate } from 'react-router-dom';

interface SPProjectProviderProps {
  children: ReactNode;
}

interface SPProjectContextProps {
  spProject: SPProject | null;
  selectedInspection: InspectionTypes.Inspection | null;
  selectedPOI: InspectionTypes.PointOfInterest | null;
  inspections: ManifestTypes.Inspection[] | null;
  manifest: ManifestTypes.Manifest | null;
  loading: boolean;
  resources: InspectionTypes.NoteResource[];
  stepIndex: number;
  model: THREE.Object3D | null;
  loadModelAndInspection: (file: File) => Promise<void>;
  loadModelSingleton: () => Promise<void>;
  setSelectedInspection: (manifestInspection: ManifestTypes.Inspection | null) => void;
  setSelectedPOI: (note: InspectionTypes.PointOfInterest | null) => void;
  setResources: (note: InspectionTypes.NoteResource[] | null) => void;
  setStepIndex: (index: number) => void;
  cleanUpProject: () => void;
}

const SPProjectContext = createContext<SPProjectContextProps | undefined>(undefined);

export const SPProjectProvider: React.FC<SPProjectProviderProps> = ({ children }) => {
  const [spProject, setSpProject] = useState<SPProject | null>(null);
  const [inspection, setInspection] = useState<InspectionTypes.Inspection | null>(null);
  const [manifest, setManifest] = useState<ManifestTypes.Manifest | null>(null);
  const [POI, setPOI] = useState<InspectionTypes.PointOfInterest | null>(null);
  const [inspections, setInspections] = useState<ManifestTypes.Inspection[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [model, setModel] = useState<THREE.Object3D | null>(null);
  const [resources, setResources] = useState<InspectionTypes.NoteResource[] | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);
  const [encryptedFile, setEncryptedFile] = useState<File | null>(null);
  const [operationCancelled, setOperationCancelled] = useState<boolean>(false);

  const { addProject, cacheable } = useProjects();
  const { showAlert } = useAlertContext();
  const navigate = useNavigate();

  const loadModelAndInspection = async (file: File) => {
    if (!file) {
      return;
    }
    if (spProject) {
      spProject.cleanup();
    }

    setLoading(true);
    setInspection(null);
    setPOI(null);
    setResources(null);
    setOperationCancelled(false);

    if (await SPProject.checkIfEncrypted(file)) {
      setEncryptedFile(file);
      setPasswordDialogOpen(true);
      return;
    }

    await loadProject(file);
  };

  const loadProject = async (file: File, password?: string) => {
    if (operationCancelled) {
      setLoading(false);
      cleanUpProject();
      setOperationCancelled(false);
      return;
    }
    try {
      const spproject = await SPProject.load(file, password);

      const glbFile = await spproject.getObjectFile();
      const _gLTFLoader = new GLTFLoader();
      const gltf = await _gLTFLoader.parseAsync(glbFile, '');

      setSpProject(spproject);
      setModel(gltf.scene);

      const manifest: ManifestTypes.Manifest = await spproject.getManifest();

      setManifest(manifest);
      setInspections(manifest.inspections ?? []);

      if (inspections && inspections.length === 1) {
        const inspectionData: InspectionTypes.Inspection = await spProject.getInspectionDataById(inspections[0].id);
        setStepIndex(0);
        setInspection(inspectionData);
      }
      if (cacheable) {
        const previewFile = await spproject.getPreview();

        if (previewFile) {
          const previewBlob = new Blob([await previewFile.async('blob')], { type: 'image/png' });

          const reader = new FileReader();
          reader.onloadend = () => {
            const previewBase64 = reader.result as string;
            addProject(file, previewBase64);
          };

          if (previewBlob) { reader.readAsDataURL(previewBlob); }

        } else {
          console.info('Cannot find any preview file.');
          addProject(file, null);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        error.message.includes('password') ? showAlert({
          variant: 'error',
          message: 'Invalid Password!'
        }) : showAlert({
          variant: 'error',
          message: String(error.message)
        });
      }
      cleanUpProject();
      throw new Error('Failed to load project' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const loadModelSingleton = async () => {
    setLoading(true);
    setInspection(null);
    setPOI(null);
    setResources(null);
    const base64project = window.base64projectstring;

    const byteCharacters = atob(base64project);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    const file = new File([blob], 'project.spproject');

    const spproject = await SPProject.load(file);
    const glbFile = await spproject.getObjectFile();

    const _gLTFLoader = new GLTFLoader();
    const gltf = await _gLTFLoader.parseAsync(glbFile, '');

    setSpProject(spproject);
    setModel(gltf.scene);

    const manifest: ManifestTypes.Manifest = await spproject.getManifest();

    setManifest(manifest);
    setInspections(manifest.inspections ?? []);

    if (inspections && inspections.length === 1) {
      const inspectionData: InspectionTypes.Inspection = await spProject.getInspectionDataById(inspections[0].id);
      setStepIndex(0);
      setInspection(inspectionData);
    }
    if (cacheable) {
      const previewFile = await spproject.getPreview();

      if (previewFile) {
        const previewBlob = new Blob([await previewFile.async('blob')], { type: 'image/png' });

        const reader = new FileReader();
        reader.onloadend = () => {
          const previewBase64 = reader.result as string;
          addProject(file, previewBase64);
        };

        if (previewBlob) { reader.readAsDataURL(previewBlob); }

      } else {
        console.info('Cannot find any preview file.');
        addProject(file, null);
      }
    }

    setLoading(false);
  };

  const setExternalInspection = async (manifestInspection: ManifestTypes.Inspection | null) => {
    const inspectionData: InspectionTypes.Inspection = await spProject.getInspectionDataById(manifestInspection.id);
    setStepIndex(0);
    setSelectedPOI(null);
    setInspection(inspectionData);
  };

  const setExternalStepIndex = async (index: number) => {
    const resources = await spProject.getResourceUrlsFromNote(inspection?.info.id, POI, index);
    setResources(resources);
    setStepIndex(index);
  };

  const setSelectedPOI = async (selectedPOI: InspectionTypes.PointOfInterest | null) => {
    const resources = await spProject.getResourceUrlsFromNote(inspection?.info.id, selectedPOI, stepIndex);
    setResources(resources);
    setStepIndex(0);
    setPOI(selectedPOI);
  };

  const handlePasswordChange = async (password: string) => {
    setPassword(password);
  };

  const handlePasswordSave = async (event?: Event) => {
    event?.preventDefault();

    if (password === '') {
      showAlert({
        variant: 'error',
        message: 'Password is required!'
      });
      return;
    }
    setPasswordDialogOpen(false);
    if (encryptedFile) {
      try {
        await loadProject(encryptedFile, password);
        navigate(`/model/${encryptedFile.name.replace('.spproject', '')}`);
      } catch (error) {
        cleanUpProject();
        navigate('/');
      } finally {
        setPassword('');
      }
    }
  };

  const cleanUpProject = () => {
    setInspection(null);
    setPOI(null);
    setResources(null);
    setStepIndex(0);
    setModel(null);
    setSpProject(null);
    setManifest(null);
    setInspections(null);
    setOperationCancelled(false);
  };

  return (
    <SPProjectContext.Provider value={{ manifest, resources, setResources, stepIndex, setStepIndex: setExternalStepIndex, spProject, inspections, selectedInspection: inspection, loading, model, loadModelAndInspection, loadModelSingleton, setSelectedInspection: setExternalInspection, selectedPOI: POI, setSelectedPOI: setSelectedPOI, cleanUpProject }}>
      {children}
      <PasswordDialog
        open={passwordDialogOpen}
        handleClose={() => {
          setPasswordDialogOpen(false);
          setPassword('');
          setEncryptedFile(null);
          setOperationCancelled(true);
          cleanUpProject();
          setLoading(false);
          navigate('/');
        }}
        password={password}
        setPassword={handlePasswordChange}
        handleSave={handlePasswordSave}
      />
    </SPProjectContext.Provider>
  );
};

export const useSPProject = () => {
  const context = useContext(SPProjectContext);
  if (!context) {
    throw new Error('useSPProject must be used within a SPProjectProvider');
  }
  return context;
};
