import Helper from '../../modules/helper';
import * as THREE from 'three';
function createLabelSprite(distance: number, position: THREE.Vector3): THREE.Sprite {
  const formattedDistance = Helper.formatDistance(distance);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const fontSize = 32;

  context.font = `${fontSize}px Arial`;
  const textWidth = context.measureText(formattedDistance).width;
  const contextHeight = (fontSize * 2) - 10;
  canvas.width = textWidth + fontSize;
  canvas.height = textWidth + fontSize;

  context.fillStyle = 'rgba(0, 0, 0, 0.5)';
  context.beginPath();
  context.moveTo(8, 0);
  context.arcTo(canvas.width, 0, canvas.width, fontSize, 8);
  context.arcTo(canvas.width, contextHeight, 0, contextHeight, 8);
  context.arcTo(0, contextHeight, 0, 0, 8);
  context.arcTo(0, 0, 8, 0, 8);
  context.closePath();
  context.fill();

  context.font = `${fontSize}px Arial`;
  context.fillStyle = 'white';
  context.fillText(formattedDistance, 10, fontSize + 5);

  const texture = new THREE.CanvasTexture(canvas);
  const material = new THREE.SpriteMaterial({
    map: texture, transparent: true,
    depthTest: false,
    depthWrite: true,
    sizeAttenuation: false,
  });

  const sprite = new THREE.Sprite(material);
  sprite.scale.set(0.075, 0.075, 0.075);
  sprite.position.copy(position);

  return sprite;
}
export default createLabelSprite;