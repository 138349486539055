import { BlobReader, BlobWriter, ZipReader } from '@zip.js/zip.js';
import JSZip from 'jszip';
import Constant from './constant';
import { type InspectionTypes, type ManifestTypes } from './types';

class SPProject {
  private constructor(private deserializedSPProjectFile: JSZip) { }

  public static load = async (file: File, password?: string) => {
    try {
      const isEncrypted = await SPProject.checkIfEncrypted(file);

      if (isEncrypted) {
        const decryptedBlob = await SPProject.decryptZip(file, password);
        return SPProject.loadWithJSZip(decryptedBlob);
      } else {
        return SPProject.loadWithJSZip(file);
      }
    } catch (error) {
      throw new Error(`Failed to load SPProject: ${error.message}`);
    }
  };

  public static checkIfEncrypted = async (file: File): Promise<boolean> => {
    try {
      const zipReader = new ZipReader(new BlobReader(file));
      const entries = await zipReader.getEntries();
      await zipReader.close();
      return entries.some(entry => entry.encrypted);
    } catch (error) {
      throw new Error(`Failed to check if file is encrypted: ${error.message}`);
    }
  };

  private static decryptZip = async (file: File, password?: string): Promise<Blob> => {
    try {
      const zipReader = new ZipReader(new BlobReader(file));
      const entries = await zipReader.getEntries();

      const zip = new JSZip();

      for (const entry of entries) {
        try {
          const blob = entry.encrypted
            ? await entry.getData(new BlobWriter(), { password })
            : await entry.getData(new BlobWriter());
          zip.file(entry.filename, blob);
        } catch (error) {

          throw new Error(`Error processing entry ${entry.filename}: ${error.message}`);
        }
      }

      await zipReader.close();
      return await zip.generateAsync({ type: 'blob' });
    } catch (error) {
      throw new Error(`Failed to decrypt zip file: ${error.message}`);
    }
  };

  private static loadWithJSZip = async (file: File | Blob) => {
    try {
      const _deserializedSPProjectFile = await new JSZip().loadAsync(file);
      return new SPProject(_deserializedSPProjectFile);
    } catch (error) {
      throw new Error(`Failed to load with JSZip: ${error.message}`);
    }
  };

  public getObjectFile = async () => {
    const file = this.deserializedSPProjectFile.file(Constant.FileName.object);
    if (file == null) throw new Error('Could not find GLB in the project.');

    const arrayBuffer = await file.async('arraybuffer');
    return arrayBuffer;
  };

  public getPreview = async () => {
    const file =
      this.deserializedSPProjectFile.file(Constant.FileName.thumbnail) ??
      this.deserializedSPProjectFile.file(Constant.FileName.preview);

    return file;
  };

  public cleanup = () => {
    this.deserializedSPProjectFile = null;
  };

  public getManifest = async (): Promise<ManifestTypes.Manifest> => {
    const file = this.deserializedSPProjectFile.file(Constant.FileName.manifest);
    if (file == null) throw new Error(`Could not find ${Constant.FileName.manifest} in the project.`);

    const JSONString = await file.async('string');
    const manifestData = JSON.parse(JSONString) as ManifestTypes.Manifest;

    this.modifyManifestForCompatibility(manifestData);
    return manifestData;
  };

  public getInspectionDataById = async (inspectionId: string): Promise<InspectionTypes.Inspection> => {
    const inspectionJSONFile = this.deserializedSPProjectFile?.file(`${Constant.FolderName.inspections}/${inspectionId}/${Constant.FileName.inspection}`);
    const inspectionJSONString = await inspectionJSONFile?.async('string');
    if (inspectionJSONString == null) throw new Error(`Could not find ${Constant.FileName.inspection} in the ${Constant.FolderName.inspections} folder.`);

    const inspectionData = JSON.parse(inspectionJSONString) as InspectionTypes.Inspection;
    this.modifyInspectionForCompatibility(inspectionData);
    return inspectionData;
  };

  public getResourceUrlsFromNote = async (inspectionId: string, note: InspectionTypes.PointOfInterest, index = 0): Promise<InspectionTypes.NoteResource[]> => {
    const noteResources: InspectionTypes.NoteResource[] = [];
    const resources = note?.steps?.[index]?.resources ?? [];

    for (const resource of resources) {
      const file = this.deserializedSPProjectFile?.file(`${Constant.FolderName.inspections}/${inspectionId}/${resource.id}.${resource.name}`);
      const blob = new Blob([await file?.async('blob')], { type: Constant.ContentType[resource.type] });
      const urlCreator = window.URL || window.webkitURL;
      const resourceUrl = blob ? urlCreator.createObjectURL(blob) : '';

      noteResources.push({ type: resource.type as InspectionTypes.ResourceType, resourceUrl: resourceUrl, name: resource.name });
    }

    return noteResources;
  };

  private modifyManifestForCompatibility(manifestData: ManifestTypes.Manifest) {
    manifestData.inspections = manifestData.inspections?.filter(inspection => !inspection.isTemplate);
  }

  private modifyInspectionForCompatibility(inspectionData: InspectionTypes.Inspection) {
    if (inspectionData.notes != null) {
      inspectionData.pointOfInterests = inspectionData.notes;
      delete inspectionData.notes;
    }

    for (const pointOfInterest of inspectionData.pointOfInterests) {
      if (pointOfInterest.status != null || pointOfInterest.noticeType != null) {
        pointOfInterest.steps = [{
          noticeType: pointOfInterest.noticeType ?? pointOfInterest.status,
          description: pointOfInterest.description,
          comment: pointOfInterest.comment ?? pointOfInterest.message,
          drawings: pointOfInterest.drawings,
          measurements: pointOfInterest.measurements,
          resources: pointOfInterest.resources ?? pointOfInterest.documents,
          constraints: pointOfInterest.constraints,
          metadata: pointOfInterest.metadata,
          dateCreated: pointOfInterest.dateCreated,
          dateModified: pointOfInterest.dateModified
        }] as InspectionTypes.Step[];

        delete pointOfInterest.description;
        delete pointOfInterest.message;
        delete pointOfInterest.noticeType;
        delete pointOfInterest.status;
        delete pointOfInterest.drawings;
        delete pointOfInterest.measurements;
        delete pointOfInterest.resources;
        delete pointOfInterest.constraints;
        delete pointOfInterest.dateCreated;
        delete pointOfInterest.dateModified;
      }

      for (const step of pointOfInterest?.steps ?? []) {
        let noticeType = (step.noticeType ?? step.status ?? 'NotChecked') as InspectionTypes.Status;

        if (noticeType === 'Default' || noticeType === 'None') noticeType = 'NotChecked';
        else if (noticeType === 'Alert') noticeType = 'Warning';

        step.noticeType = noticeType;
        delete step.status;

        step.comment ??= step.message;
        delete step.message;
      }
    }
  }
}

export default SPProject;
