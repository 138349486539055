import React, { createContext, useContext, useState, type ReactNode } from 'react';

interface ModalProviderProps {
  children: ReactNode;
}

interface ModalContextProps {
  isInspectionListModalOpen: boolean;
  openInspectionListModal: () => void;
  closeInspectionListModal: () => void;

  isInspectionDetailModalOpen: boolean;
  openInspectionDetailModal: () => void;
  closeInspectionDetailModal: () => void;

  isMeasurementColorModalOpen: boolean;
  openMeasurementColorModal: () => void;
  closeMeasurementColorModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isInspectionListModalOpen, setIsInspectionListModalOpen] = useState(false);
  const [isInspectionDetailModalOpen, setIsInspectionDetailModalOpen] = useState(false);
  const [isMeasruementColorModalOpen, setIsMeasruementColorModal] = useState(false);

  const openInspectionListModal = () => {
    setIsInspectionListModalOpen(true);
  };

  const closeInspectionListModal = () => {
    setIsInspectionListModalOpen(false);
  };

  const openInspectionDetailModal = () => {
    setIsInspectionDetailModalOpen(true);
  };

  const closeInspectionDetailModal = () => {
    setIsInspectionDetailModalOpen(false);
  };
  const openMeasruementColorModal = () => {
    setIsMeasruementColorModal(true);
  };

  const closeMeasruementColorModal = () => {
    setIsMeasruementColorModal(false);
  };
  return (
    <ModalContext.Provider value={{
      isMeasurementColorModalOpen: isMeasruementColorModalOpen,
      closeMeasurementColorModal: closeMeasruementColorModal,
      openMeasurementColorModal: openMeasruementColorModal,
      isInspectionListModalOpen,
      openInspectionListModal,
      closeInspectionListModal,
      isInspectionDetailModalOpen,
      openInspectionDetailModal,
      closeInspectionDetailModal
    }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
