/* eslint-disable @typescript-eslint/no-namespace */
import { type InspectionTypes } from './types';

namespace Constant {
  export namespace FileName {
    export const object = 'object.glb';
    export const manifest = 'manifest.json';
    export const inspection = 'inspection.json';
    export const preview = 'preview.png';
    export const thumbnail = 'thumbnail.png';
  }

  export namespace FileExt {
    export const project = 'spproject';
  }

  export namespace FolderName {
    export const inspections = 'inspections';
  }

  export namespace ObjectTag {
    export const label = 'node-point';
    export const drawingLine = 'drawing-line';
    export const mainObject = 'main-object';
  }

  export const ContentType: Record<InspectionTypes.ResourceType, string> = {
    Image: 'image/jpg',
    Pdf: 'application/pdf'
  };
}

export default Constant;
