import React from 'react';
import InspectionListModal from './inspection-list/inspection-list';
import InspectionDetailModal from './inspection-detail/inspection-detail';
import MeasurementColorModal from '../measurement/measurement-color-modal';


const ModalManager: React.FC = () => (
  <>
    <InspectionListModal />
    <InspectionDetailModal />
    <MeasurementColorModal />
  </>
);

export default ModalManager;
