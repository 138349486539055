import cube from '../../../assets/cube.png';
import cubeActive from '../../../assets/cube-active.png';
import perspective from '../../../assets/perspective-new.png';
import perspectiveActive from '../../../assets/perspective-new-active.png';
import refresh from '../../../assets/refresh.png';
import measure from '../../../assets/measure.png';
import measureActive from '../../../assets/measure-active.png';

import { useCamera } from '../../../providers/camera';
import { useMeasurement } from '../../../providers/measurement-provider';
import { useModal } from '../../../providers/modal';
import { NavHelper, NavHelperImage, NavHelperItem, SubMenuMeasurement } from './action-buttons.styled';

import { SvgTrashSolid, SvgPaletteSolid, LineAwesome } from 'react-line-awesome-svg';

function ActionButtons() {
  const { isPerspective, setPerspective, setRefresh } = useCamera();
  const { isMeasuring, startMeasurement, stopMeasurement, setClearMeasurement } = useMeasurement();
  const { openMeasurementColorModal: openMeasruementColorModal } = useModal();

  return (
    <NavHelper>
      <NavHelperItem onClick={() => { if (isMeasuring) { stopMeasurement(); } else { startMeasurement(); } }}  >
        <NavHelperImage src={!isMeasuring ? measure : measureActive} />
      </NavHelperItem>
      {isMeasuring ? <SubMenuMeasurement>
        <LineAwesome icon={SvgTrashSolid} onClick={() => { setClearMeasurement(true); }}
        />
        <LineAwesome icon={SvgPaletteSolid} onClick={() => { openMeasruementColorModal(); }} />
      </SubMenuMeasurement> : <></>}
      <NavHelperItem onClick={() => { setPerspective(true); }} id="perspective-btn">
        <NavHelperImage src={!isPerspective ? cube : cubeActive} style={!isPerspective ? { filter: 'brightness(0) invert(1)' } : {}} />
      </NavHelperItem>
      <NavHelperItem onClick={() => { setPerspective(false); }} id="orthographic-btn">
        <NavHelperImage src={isPerspective ? perspective : perspectiveActive} style={isPerspective ? { filter: 'brightness(0) invert(1)' } : {}} />
      </NavHelperItem>
      <hr className="my-2" style={{ color: '#ccc' }} />
      <NavHelperItem onClick={() => { setRefresh(true); }}>
        <NavHelperImage src={refresh} />
      </NavHelperItem>
    </NavHelper>
  );
}

export default ActionButtons;