import { Sphere } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { Fragment, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Line2, LineGeometry, LineMaterial } from 'three-stdlib';
import { useMeasurement } from '../../providers/measurement-provider';
import { useSPProject } from '../../providers/spproject';
import createLabelSprite from './measurement-label';

interface Measurement {
  start: [number, number, number];
  end: [number, number, number];
  isCompleted: boolean;
  distance?: number;
}

const MeasurementHandler = () => {
  const { camera } = useThree();
  const { model } = useSPProject();
  const raycaster = new THREE.Raycaster();
  const [isClicking, setIsClicking] = useState(false);
  const [mouse, setMouse] = useState(new THREE.Vector2());

  const [measurementLines, setMeasurementLines] = useState<Measurement[]>([]);

  const lineGroupRefs = useRef<Array<THREE.Group | null>>([]);

  const { isMeasuring, measurementColor, clearMeasurement, setClearMeasurement } = useMeasurement();

  const startNewLine = (newPoint) => {
    if (measurementLines.length > 0 && !measurementLines[measurementLines.length - 1].isCompleted) {
      const lastMeasurementLine = measurementLines[measurementLines.length - 1];
      const updatedLastLine: Measurement = { ...lastMeasurementLine, isCompleted: false };
      setMeasurementLines([...measurementLines.slice(0, -1), updatedLastLine]);
    } else {
      const newLine: Measurement = { start: newPoint, end: null, isCompleted: false };
      setMeasurementLines([...measurementLines, newLine]);
    }
  };

  const handleModelClick = (vectorEvent: THREE.Vector3) => {
    const newPoint = [vectorEvent.x, vectorEvent.y, vectorEvent.z] as [number, number, number];

    if (measurementLines.length > 0) {
      const lastMeasurement = measurementLines[measurementLines.length - 1];

      if (!lastMeasurement.isCompleted) {
        const updatedLines = measurementLines.map((measurement, index) => {
          if (index === measurementLines.length - 1) {
            return { ...measurement, end: newPoint, isCompleted: true, distance: calculateDistance(measurement.start, newPoint) };
          } else {
            return measurement;
          }
        });

        setMeasurementLines(updatedLines);
      } else {
        startNewLine(newPoint);
      }
    } else {
      startNewLine(newPoint);
    }
  };

  function calculateDistance(start: [number, number, number], end: [number, number, number]): number {
    const vectorStart = new THREE.Vector3(...start);
    const vectorEnd = new THREE.Vector3(...end);
    return vectorStart.distanceTo(vectorEnd);
  }

  function applyLines() {
    measurementLines.forEach((measurement, index) => {
      const group = lineGroupRefs.current[index];
      if (measurement.isCompleted) {
        if (group) {
          group.clear();
        }

        const positions = [measurement.start, measurement.end].map(point => new THREE.Vector3(...point));
        const pts: number[] = [];

        for (const pt of positions) {
          pts.push(pt.x, pt.y, pt.z);
        }

        const geometry = new LineGeometry();
        geometry.setPositions(pts);

        const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);

        const material = new LineMaterial({
          color: measurementColor,
          resolution,
          linewidth: 2,
          transparent: true,
          depthTest: false,
          depthWrite: false,
        });

        const line = new Line2(geometry, material);
        line.computeLineDistances();

        const lineGroup = new THREE.Group();
        lineGroup.add(line);

        const midPoint = new THREE.Vector3().addVectors(positions[0], positions[1]).multiplyScalar(0.5);
        const labelSprite = createLabelSprite(measurement.distance, midPoint);
        lineGroup.add(labelSprite);
        group?.add(lineGroup);
      }
    });
  }

  const onClick = (event) => {
    event.preventDefault();

    const { offsetX, offsetY, target } = event;

    const rect = target.getBoundingClientRect();
    const x = ((offsetX - rect.left) / rect.width) * 2 - 1;
    const y = -((offsetY - rect.top) / rect.height) * 2 + 1;

    setMouse(new THREE.Vector2(x, y));
    setIsClicking(true);
  };

  useEffect(() => {
    const eventName = 'click';
    const canvas = document.querySelector('#threejs-canvas');

    canvas.removeEventListener(eventName, onClick);

    if (isMeasuring) {
      canvas.addEventListener(eventName, onClick);
    }

    return () => {
      canvas.removeEventListener(eventName, onClick);
    };
  }, [isMeasuring]);

  useEffect(() => {
    applyLines();
  }, [measurementLines, measurementColor]);

  useFrame(() => {
    if (isClicking) {

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(model.children, true);

      if (intersects.length > 0) {
        handleModelClick(intersects[0].point);
      }
      setIsClicking(false);
    }
  });

  useEffect(() => {
    if (clearMeasurement) {
      setMeasurementLines([]);
      setClearMeasurement(false);
    }
  }, [clearMeasurement, setClearMeasurement]);

  return (
    <>
      {
        measurementLines.map((measurement, lineIndex) => (
          <Fragment key={lineIndex}>
            <Sphere key={0} position={measurement.start} args={[0.007, 32, 32]} >
              <meshBasicMaterial color={measurementColor} depthWrite={false} depthTest={false} />
            </Sphere>
            {measurement.end != null ? <Sphere key={1} position={measurement.end} args={[0.007, 32, 32]}>
              <meshBasicMaterial color={measurementColor} depthWrite={false} depthTest={false} />
            </Sphere> : <></>}
            <group ref={(ref) => (lineGroupRefs.current[lineIndex] = ref)} />
          </Fragment>
        ))
      }
    </>
  );
};

export default MeasurementHandler;
