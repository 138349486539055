import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { type FC } from 'react';

interface PasswordDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  password: string;
  setPassword: (password: string) => void;
}

const PasswordDialog: FC<PasswordDialogProps> = ({ open, handleClose, password, setPassword, handleSave }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="password-dialog-title">

    <form id='password-form' onSubmit={handleSave}>
      <Stack spacing={6}>
        <DialogTitle id="password-dialog-title">
          Enter Password
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder='Password'
            focused
            autoComplete='new-password'
            size='small'
            type="password"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            autoFocus
          />
        </DialogContent>
      </Stack>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button type='submit' form="password-form" color="primary">
          OK
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default PasswordDialog;
