import React from 'react';

import ModelViewer from '../../components/model';
import ActionButtons from '../../components/user-interface/action-buttons/action-buttons';

import ModalManager from '../../components/user-interface/modals';

import NavOverlay from '../../components/user-interface/nav-overlay/nav-overlay';

const IndexPage: React.FC = () => (
  <div style={{ backgroundColor: '#383838', height: '100vh' }}>
    <NavOverlay />
    <ModelViewer />
    <ActionButtons />
    <ModalManager />
  </div>
);

export default IndexPage;
