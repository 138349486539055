import React, { type FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteDialog: FC<DeleteDialogProps> = ({ open, handleClose, handleDelete }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="delete-dialog-title"
    aria-describedby="delete-dialog-description"
  >
    <DialogTitle id="delete-dialog-title">
      {'Delete Confirmation'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="delete-dialog-description">
        Are you sure you want to delete this item?
      </DialogContentText>
      <DialogContentText id="delete-dialog-confirmation">
        This action cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleDelete} color="error" autoFocus>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
