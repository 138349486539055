
import { Box, FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DashboardLeftSide from '../dashboard/left-side/left-side';

import { useProjects } from '../../providers/projects';
import { presetsObj, type PresetsType } from '@react-three/drei/helpers/environment-assets';
import { useCamera } from '../../providers/camera';
import Helper from '../../modules/helper';
import { SettingsCard, SettingsContent, SettingsRightSide, SettingsTopSide } from './settings.styled';
import { StyledWarningParagraph } from '../dashboard/dashboard.styled';


const SettingsPage: React.FC = () => {
  const { setCacheable, cacheable } = useProjects();
  const { setRoomEnvironment, roomEnvironment } = useCamera();

  const handleChange = () => {
    setCacheable(!cacheable);
  };

  const [selectedEnvironment, setSelectedEnvironment] = useState<PresetsType>('warehouse');

  const handleChangeEnvironment = (val: string) => {
    setSelectedEnvironment(val as PresetsType);
    setRoomEnvironment(val as PresetsType);
  };

  useEffect(() => {
    setSelectedEnvironment(roomEnvironment);
  }, []);

  return (
    <SettingsContent>
      <DashboardLeftSide />
      <SettingsRightSide>
        <SettingsTopSide>
          <div className="container">
            <h5 className="page-title ml-2 mb-0">Settings</h5>
          </div>
        </SettingsTopSide>
        <div className="container">
          <div className="my-4">
            <SettingsCard>
              <SettingsCard.Body>
                <h6 className='m-0'>SPProject Browser Cache</h6>
                <Switch checked={cacheable} onChange={handleChange} name="disableProjectCaching" />
              </SettingsCard.Body>
            </SettingsCard>
          </div>
          <div className="my-4">
            <SettingsCard>
              <SettingsCard.Body>
                <h6 className='m-0'>Viewer Room Environment</h6>
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Room Environment</InputLabel>
                    <Select
                      labelId="room-environment-label"
                      id="room-environment-select"
                      value={selectedEnvironment}
                      label="Select Environment"
                      onChange={(val) => { handleChangeEnvironment(val.target.value); }}
                    >
                      {Object.entries(presetsObj)
                        .reverse()
                        .map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value === 'empty_warehouse_01_1k.hdr' ? Helper.capitalizeFirstLetter(key) + ' (DEFAULT)' : Helper.capitalizeFirstLetter(key)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </SettingsCard.Body>
            </SettingsCard>
          </div>
        </div>
        <StyledWarningParagraph>This application runs completely locally without sending any data to the servers.</StyledWarningParagraph>
      </SettingsRightSide>
    </SettingsContent>
  );
};

export default SettingsPage;
