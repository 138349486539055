import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { type ProjectInfo } from '../../../modules/spproject/types';
import Helper from '../../../modules/helper';
import { useFileContext } from '../../../providers/file';

import { DeleteProjectIcon, NoImageContainer, ProjectCardBody, ProjectCardContainer, ProjectCardImg, ProjectCardTitle }
  from './project-card.styled';
import { LineAwesome, SvgImageSolid, SvgTimesSolid } from 'react-line-awesome-svg';

interface ProjectCardProps {
  project: ProjectInfo;
  onDeleteClick: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onDeleteClick }) => {
  const { setSelectedFile } = useFileContext();

  const openProject = async () => {
    try {
      const file = project.file;
      setSelectedFile(file);
    } catch (error) {
      console.error('Dosya alınamadı:', error);
    }
  };

  return (
    <ProjectCardContainer className='project-card m-0'>
      <OverlayTrigger
        key='clear-projects-tooltip'
        placement='top'
        overlay={
          <Tooltip id='tooltip-top'>
            Remove Project
          </Tooltip>
        }
      >
        <DeleteProjectIcon icon={SvgTimesSolid} onClick={onDeleteClick} />
      </OverlayTrigger>
      {project.previewImageBase64 ? (
        <ProjectCardImg onClick={openProject} variant="top" src={project.previewImageBase64} alt={project.name} />
      ) : (
        <NoImageContainer onClick={openProject}><LineAwesome icon={SvgImageSolid} fill='white' /></NoImageContainer>
      )}
      <ProjectCardBody onClick={openProject}>
        <ProjectCardTitle>{project.name}</ProjectCardTitle>
        <Card.Text>
          {project.date} {Helper.humanFileSize(project.fileSize)}
        </Card.Text>
      </ProjectCardBody>
    </ProjectCardContainer>
  );
};

export default ProjectCard;
