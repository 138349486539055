import React, { useState, useEffect } from 'react';
import { Canvas, extend, useThree } from '@react-three/fiber';
import { Spinner } from 'react-bootstrap';
import { useSPProject } from '../providers/spproject';
import * as THREE from 'three';
import { Environment } from '@react-three/drei';
import GridWithLabels from './grid-with-labels';
import InspectionPoints from './inspection-points';
import CameraController from './camera-controller';
import { useCamera } from '../providers/camera';
import MeasurementHandler from './measurement/measurement';
import { FloatType } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import DrawLines from './draw-lines';

extend({ Line_: THREE.Line });

const SkySphere = ({ blobUrl }) => {
  const { gl, scene } = useThree();
  const pmremGenerator = new THREE.PMREMGenerator(gl);
  const loader = new RGBELoader();
  loader.setDataType(FloatType);
  pmremGenerator.compileEquirectangularShader();

  useEffect(() => {
    loader.load(blobUrl, texture => {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      scene.environment = envMap;

      texture.dispose();
      pmremGenerator.dispose();
    });
  }, [scene, loader, pmremGenerator]);

  return null;
};

const ModelViewer: React.FC = () => {
  const { model, selectedInspection, loading } = useSPProject();
  const { roomEnvironment } = useCamera();
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const isSingleton = window.isWebpackSingle;

  useEffect(() => {
    const fetchHDRI = async () => {
      try {
        console.log(`base64projecthdri:${window.base64projecthdri}`);
        const defaultHdriBase64 = window.base64projecthdri;
        const byteCharacters = atob(defaultHdriBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);
        setBlobUrl(blobUrl);
      } catch (error) {
        console.error('Error fetching HDRI:', error);
      }
    };

    if (isSingleton) fetchHDRI();
  }, []);

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner animation="border" variant="light" />
      </div>
    );
  }

  return (
    <Canvas id="threejs-canvas"
      style={{ width: '100%', backgroundColor: 'transparent' }}
      gl={{ antialias: true, powerPreference: 'high-performance', alpha: true }}
      linear
    >
      {model && (
        <primitive object={model} />
      )}
      {selectedInspection != null && selectedInspection.pointOfInterests.length > 0 ? (
        <InspectionPoints inspection={selectedInspection} />
      ) : (
        <></>
      )}
      {(isSingleton && blobUrl) && (
        <SkySphere blobUrl={blobUrl} />
      )}
      {!isSingleton && <Environment preset={roomEnvironment} />}
      <CameraController model={model} />
      <GridWithLabels model={model} />
      <DrawLines />
      <MeasurementHandler />
    </Canvas>
  );
};

export default ModelViewer;
