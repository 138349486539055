import styled from 'styled-components';
import { Button, ListGroup } from 'react-bootstrap';

export const OverlayContainer = styled.div`
  width: 250px;
  left: 18px;
  top: 18px;
  position: absolute;
  z-index: 999;
`;

export const OverlayMenuButton = styled(Button)`
  background-color: #323232;
  color: white;
  border-color: #525252;
  border-radius: 2px;
  padding: 25px;
  font-size: 26px;
  display: flex;

  i,svg{
    color:white;
    fill:white;
  }
`;


export const OverlayList = styled(ListGroup)`
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
`;

export const OverlayListItem = styled(ListGroup.Item)`
  background-color: #292929;
  color: #a9a9a9;
  display: flex;
  align-items: center;
  padding: 25px;

  i, svg {
    width: 36px;
    font-size: 26px;
    color: white;
    fill: white;
    margin-right: 12px;
    transition: color 0.3s ease;
  }

  &:hover {
    i, svg {
      color: black;
      fill:black;
    }
  }
`;
