import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useCamera } from '../providers/camera';
import { useThree } from '@react-three/fiber';
import { OrbitControls, OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { type ModelProps } from '../modules/spproject/types';
import { useMeasurement } from '../providers/measurement-provider';

const CameraController: React.FC<ModelProps> = ({ model }) => {
  const camera = useThree((state) => state.camera);
  const gl = useThree((state) => state.gl);
  const controls = useRef(null);

  const [loaded, setLoaded] = useState<boolean>(false);

  const { isPerspective, isRefreshed, setRefresh } = useCamera();

  const persRef = useRef<THREE.PerspectiveCamera | null>(null);
  const orthoRef = useRef<THREE.OrthographicCamera | null>(null);

  const fov = 50;
  const { isMeasuring } = useMeasurement();
  const pixelsFromCenterToTop = useThree((state) => state.size.height / 2);
  const fovFactor = Math.tan(((fov / 2) * Math.PI) / 180) / pixelsFromCenterToTop;

  const persDistanceToOrthoZoom = (distance: number) => 1 / fovFactor / distance;
  const orthoZoomToPersDistance = (zoom: number) => 1 / zoom / fovFactor;

  useEffect(() => {
    if (isRefreshed === true) {
      setObjectToCenter();
      setRefresh(false);
    }
  }, [isRefreshed]);

  useEffect(() => {
    if (loaded) {
      setObjectToCenter();
    }
  }, [model]);

  useEffect(() => {
    if (controls.current) {
      if (isPerspective) {
        persRef.current.position.copy(orthoRef.current.position.clone());
        const distance = orthoZoomToPersDistance(orthoRef.current.zoom);
        persRef.current.position.setLength(distance);
      } else {
        orthoRef.current.position.copy(persRef.current.position.clone());
        orthoRef.current.zoom = persDistanceToOrthoZoom(orthoRef.current.position.length());
        orthoRef.current.updateProjectionMatrix();
      }
    }
    if (!loaded) {
      setObjectToCenter();
      setLoaded(true);
    }

    const bbox = new THREE.Box3().setFromObject(model);
    const objectCenter = bbox.getCenter(new THREE.Vector3());

    controls.current.target = new THREE.Vector3(objectCenter.x, objectCenter.y, objectCenter.z);
    controls.current.update();

  }, [isPerspective, controls, camera]);

  function setObjectToCenter() {
    const bbox = new THREE.Box3().setFromObject(model);
    const objectCenter = bbox.getCenter(new THREE.Vector3());
    const size = bbox.getSize(new THREE.Vector3());

    const distance = Math.max(size.x, size.y, size.z);

    camera.position.set(objectCenter.x - distance, objectCenter.y + distance, objectCenter.z + distance);

    camera.lookAt(objectCenter);
    camera.updateProjectionMatrix();

    controls.current.target = new THREE.Vector3(objectCenter.x, objectCenter.y, objectCenter.z);
    controls.current.update();

    orthoRef.current.zoom = persDistanceToOrthoZoom(orthoRef.current.position.length());
  }

  useEffect(() => {
    if (controls.current) {
      controls.current.enableRotate = !isMeasuring;
    }
  }, [isMeasuring]);

  return <>
    <OrbitControls makeDefault ref={controls}
      args={[camera, gl.domElement]}
      enableDamping={true}
      dampingFactor={0.25}
      rotateSpeed={0.5} />
    <PerspectiveCamera ref={persRef} makeDefault={isPerspective} fov={fov} />
    <OrthographicCamera ref={orthoRef} makeDefault={!isPerspective} />
  </>;
};

export default CameraController;
