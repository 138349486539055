import React, { useState } from 'react';
import { Col, Container, FormControl, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import ProjectCard from '../../../components/user-interface/project-card/project-card';
import { useProjects } from '../../../providers/projects';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Helper from '../../../modules/helper';
import { useFileContext } from '../../../providers/file';

import DeleteDialog from '../../../components/dialogs/delete-dialog';
import { type ProjectInfo } from '../../../modules/spproject/types';
import { DashboardRightSideContainer, DashboardTopSide, SearchBar, StyledContainer, StyledDashboardRightContent, StyledExclamationCircle, StyledHeader, StyledIcon, StyledNoProjectsContainer, StyledParagraph, StyledProjectHeader, StyledProjectsTitle, StyledWarningParagraph } from '../dashboard.styled';
import { LineAwesome, SvgAngleRightSolid, SvgBorderAllSolid, SvgExclamationCircleSolid, SvgListAlt, SvgSearchSolid, SvgTimesSolid } from 'react-line-awesome-svg';

const DashboardRightSide: React.FC = () => {
  const { projects, isLoading, clearAllProjects, search, setSearch, isGridLayout, setIsGridLayout, deleteProject } = useProjects();
  const { setSelectedFile } = useFileContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProjectForDelete, setSelectedProjectForDelete] = useState<ProjectInfo | null>(null);
  const [clearAllDialogOpen, setClearAllDialogOpen] = useState(false); // New state for clear all confirmation dialog

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const openProject = async (project: ProjectInfo) => {
    try {
      const file = project.file;
      setSelectedFile(file);
    } catch (error) {
      console.error('Dosya alınamadı:', error);
    }
  };

  const handleDeleteProject = () => {
    if (selectedProjectForDelete) {
      deleteProject(selectedProjectForDelete.fileUrl);
      setDeleteDialogOpen(false);
      setSelectedProjectForDelete(null);
    }
  };

  const handleDeleteDialogOpen = (project: ProjectInfo) => {
    setSelectedProjectForDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedProjectForDelete(null);
  };

  const handleClearAllDialogOpen = () => {
    setClearAllDialogOpen(true);
  };

  const handleClearAllDialogClose = () => {
    setClearAllDialogOpen(false);
  };

  const handleClearAllProjects = () => {
    setClearAllDialogOpen(false);
    clearAllProjects();
  };


  return (<>
    <DeleteDialog
      open={deleteDialogOpen}
      handleClose={handleDeleteDialogClose}
      handleDelete={() => { handleDeleteProject(); }}
    />
    <DeleteDialog
      open={clearAllDialogOpen}
      handleClose={handleClearAllDialogClose}
      handleDelete={() => { handleClearAllProjects(); }}
    />
    <DashboardRightSideContainer>
      <DashboardTopSide>
        <SearchBar>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text><LineAwesome icon={SvgSearchSolid} fill='white' /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl style={{ color: 'white' }} placeholder="Search"
              value={search || ''}
              onChange={handleSearchChange} />
          </InputGroup>
        </SearchBar>
      </DashboardTopSide>
      <StyledDashboardRightContent>
        <StyledProjectHeader>
          <StyledProjectsTitle>
            Projects <LineAwesome icon={SvgAngleRightSolid} fill='white' className='ml-2' />
          </StyledProjectsTitle>
          <div className='d-flex'>
            <OverlayTrigger
              key='clear-projects-tooltip'
              placement='top'
              overlay={
                <Tooltip id='tooltip-top'>
                  Clear All Projects
                </Tooltip>
              }
            >
              <StyledIcon icon={SvgTimesSolid} onClick={handleClearAllDialogOpen} />
            </OverlayTrigger>
            <OverlayTrigger
              key='change-to-grid-tooltip'
              placement='top'
              overlay={
                <Tooltip id='tooltip-top'>
                  Change View to Grid
                </Tooltip>
              }
            >
              <StyledIcon icon={isGridLayout ? SvgBorderAllSolid : SvgListAlt} onClick={() => setIsGridLayout(!isGridLayout)} />
            </OverlayTrigger>
          </div>
        </StyledProjectHeader>
        {isLoading ? (
          <StyledContainer fluid>
            <Spinner animation="border" variant="light" />
          </StyledContainer>
        ) : (
          projects.length !== 0 ? (
            <Container fluid className='px-0 pt-4'>
              {isGridLayout ? (
                <Row xs={1} sm={2} md={2} lg={3} xl={5} className='m-0'>
                  {projects
                    .filter((project) => {
                      if (!search) {
                        return true;
                      }
                      return (
                        project.name.toLowerCase().includes(search.toLowerCase())
                      );
                    })
                    .map((project, index) => (
                      <Col key={index} className='p-0 pl-3'>
                        <ProjectCard project={project} onDeleteClick={() => { handleDeleteDialogOpen(project); }} />
                      </Col>
                    ))}
                </Row>
              ) : (

                <Table sx={{ minWidth: 100 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Project Name</TableCell>
                      <TableCell align="left" className="d-none d-sm-table-cell">Date</TableCell>
                      <TableCell align="left" className="d-none d-sm-table-cell">Size</TableCell>
                      <TableCell align="right" className="d-none d-sm-table-cell">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects
                      .filter((project) => {
                        if (!search) {
                          return true;
                        }
                        return (
                          project.name.toLowerCase().includes(search.toLowerCase())
                        );
                      })
                      .map((project) => (
                        <TableRow
                          hover
                          key={project.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          onClick={() => openProject(project)}
                        >
                          <TableCell component="th" scope="row">
                            <p className='mb-0'>{project.name}</p>
                          </TableCell>
                          <TableCell align="left" className="d-none d-sm-table-cell">
                            {project.date}
                          </TableCell>
                          <TableCell align="left" className="d-none d-sm-table-cell">
                            {Helper.humanFileSize(project.fileSize)}
                          </TableCell>
                          <TableCell align="right" className="d-none d-sm-table-cell">
                            <OverlayTrigger
                              key={`delete-tooltip-${project.name}`}
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top-${project.name}`}>
                                  Delete Project
                                </Tooltip>
                              }
                            >
                              <IconButton aria-label="delete" size="medium" onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteDialogOpen(project);
                              }} >
                                <LineAwesome icon={SvgTimesSolid} fill='white' />
                              </IconButton>
                            </OverlayTrigger>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

              )}
            </Container>
          ) : (
            <StyledNoProjectsContainer className="col-md-12">
              <StyledExclamationCircle icon={SvgExclamationCircleSolid} />
              <StyledHeader>No Projects Found</StyledHeader>
              <StyledParagraph>If the caching system is turned on in the settings, when you open a project here, you can access the local copy of your projects within the limits allowed by the browser.</StyledParagraph>
              <StyledParagraph>If you have made a change to your project, you may need to refresh the page to see the changes.</StyledParagraph>
              <StyledWarningParagraph>This application runs completely locally without sending any data to the servers.</StyledWarningParagraph>
            </StyledNoProjectsContainer>
          )
        )}
      </StyledDashboardRightContent>
    </DashboardRightSideContainer></>
  );
};
export default DashboardRightSide;