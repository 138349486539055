import React, { createContext, useContext, type ReactNode, useState, type Dispatch, type SetStateAction } from 'react';
import AlertBar from '../components/alert-bar';

interface AlertContextProps {
  alertOpen: boolean;
  setAlertOpen: Dispatch<SetStateAction<boolean>>;
  alertMessage: string;
  alertVariant: 'success' | 'error' | 'warning' | 'info';
  showAlert: (props: { variant: 'success' | 'error' | 'warning' | 'info'; message: string }) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  const showAlert = ({ variant, message }: { variant: 'success' | 'error' | 'warning' | 'info'; message: string }) => {
    setAlertVariant(variant);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const value: AlertContextProps = {
    alertOpen,
    setAlertOpen,
    alertMessage,
    alertVariant,
    showAlert,
  };

  return (
    <AlertContext.Provider value={value}>
      {children}
      <AlertBar />
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within a AlertProvider');
  }
  return context;
};