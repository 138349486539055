import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAlertContext } from '../providers/alert';

const AlertBar: React.FC = () => {
  const { alertOpen, setAlertOpen, alertMessage, alertVariant } = useAlertContext();

  return (
    <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
      <Alert
        onClose={() => setAlertOpen(false)}
        severity={alertVariant}
        variant="filled"
        sx={{ width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0 }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;