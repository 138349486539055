import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { LineAwesome } from 'react-line-awesome-svg';

export const DashboardContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #262626;
  color: white;
  display: flex;
`;

export const DashboardRightSideContainer = styled.div`
  width: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden;
`;
export const DashboardTopSide = styled.div`
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #525252;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 26px;
`;
export const SearchBar = styled.div`
width: 350px;
margin-left: 28px;

.input-group-text {
  background-color: transparent;
  border: none;
}

.form-control {
  background-color: transparent;
  border: none;

  &::placeholder,
  i {
    color: white;
  }
}
`;
export const StyledDashboardRightContent = styled.div`
  flex: 1;
  padding: 36px;
  color: #bebdbe;
  padding-left: 16px;
`;

export const StyledDashboardLeftSide = styled.div`
  width: 100px;
  height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLogo = styled.div`
width: 100%;
height: 100px;
padding: 18px;
display: flex;
align-items: center;
justify-content: center;
`;

export const StyledDashboardLeftSideMenu = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const StyledMenuButton = styled.label`
  border-radius: 8px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin: 8px;
  color: white;
  background-color: #323232;
  cursor: pointer;
  margin-bottom: 0px;
  transition: background-color 0.3s ease;
  i,svg{
    color:white;
    fill:white;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const StyledContainer = styled(Container)`
overflow-y: auto;
height: calc(100vh - 215px);
display: flex;
align-items: center;
justify-content: center;
`;
export const StyledNoProjectsContainer = styled.div`
  height: calc(100vh - 215px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const StyledExclamationCircle = styled(LineAwesome)`
  font-size: 78px;
  margin-bottom: 3px;
  fill:white;
`;

export const StyledHeader = styled.h4`
  margin-bottom: 18px;
`;

export const StyledParagraph = styled.p`
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const StyledWarningParagraph = styled(StyledParagraph)`
  color: yellow;
`;

export const StyledProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProjectsTitle = styled.p`
  margin-bottom: 2px;
  padding-left: 14px;
`;

export const StyledIcon = styled(LineAwesome)`
  font-size: 22px;
  margin-right: 18px;
  cursor: pointer;
  fill:white;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;
