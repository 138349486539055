import React, { createContext, useContext, type ReactNode, useState } from 'react';

export enum MeasurementColor {
  RED = 0xF44336,
  PINK = 0xE91E63,
  PURPLE = 0x9C27B0,
  DEEP_PURPLE = 0x673AB7,
  INDIGO = 0x3F51B5,
  BLUE = 0x2196F3,
  LIGHT_BLUE = 0x03A9F4,
  CYAN = 0x00BCD4,
  TEAL = 0x009688,
  GREEN = 0x4CAF50,
  LIGHT_GREEN = 0x8BC34A,
  LIME = 0xCDDC39,
  YELLOW = 0xFFEB3B,
  AMBER = 0xFFC107,
  ORANGE = 0xFF9800,
  DEEP_ORANGE = 0xFF5722,
  BROWN = 0x795548,
  GREY = 0x9E9E9E,
  BLUE_GREY = 0x607D8B,
  WHITE = 0xFFFFFF,
}

interface MeasurementContextProps {
  isMeasuring: boolean;
  startMeasurement: () => void;
  stopMeasurement: () => void;
  clearMeasurement: boolean;
  setClearMeasurement: (clear: boolean) => void;
  measurementColor: MeasurementColor;
  setMeasurementColor: (color: MeasurementColor) => void;
  hexColor: (color: MeasurementColor) => string;
}

const MeasurementContext = createContext<MeasurementContextProps | undefined>(undefined);

export const MeasurementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMeasuring, setIsMeasuring] = useState<boolean>(false);
  const [clearMeasurement, setClearMeasurement] = useState<boolean>(false); // Yeni eklendi
  const [measurementColor, setMeasurementColor] = useState<MeasurementColor>(MeasurementColor.ORANGE);

  const startMeasurement = () => {
    setIsMeasuring(true);
  };

  const stopMeasurement = () => {
    setIsMeasuring(false);
  };

  const hexColor = (color: MeasurementColor) => '#' + color.toString(16).padStart(6, '0');

  const value: MeasurementContextProps = {
    hexColor,
    isMeasuring,
    startMeasurement,
    stopMeasurement,
    clearMeasurement,
    setClearMeasurement,
    measurementColor,
    setMeasurementColor,
  };

  return <MeasurementContext.Provider value={value}>{children}</MeasurementContext.Provider>;
};

export const useMeasurement = () => {
  const context = useContext(MeasurementContext);
  if (!context) {
    throw new Error('useMeasurement must be used within a MeasurementProvider');
  }
  return context;
};

