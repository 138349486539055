import { useState } from 'react';

import { useModal } from '../../../providers/modal';
import { IconButton, Snackbar } from '@mui/material';
import { useSPProject } from '../../../providers/spproject';

import CloseIcon from '@mui/icons-material/Close';
import { useFileContext } from '../../../providers/file';
import { useNavigate } from 'react-router-dom';
import { OverlayContainer, OverlayList, OverlayListItem, OverlayMenuButton } from './nav-overlay.styled';

import { LineAwesome, SvgBarsSolid, SvgExternalLinkAltSolid, SvgHomeSolid } from 'react-line-awesome-svg';

const NavOverlay: React.FC = () => {
  const { openInspectionListModal, closeInspectionDetailModal } = useModal();
  const { inspections } = useSPProject();
  const { setSelectedFile } = useFileContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleOpenInspectionClick = () => {
    setIsActive(false);

    if (inspections.length === 0) {
      setSnackbarOpen(true);
    } else {
      openInspectionListModal();
    }
  };

  return (
    <>
      <OverlayContainer>
        {!isActive ? (
          <OverlayMenuButton variant='dark' size='lg' onClick={() => { setIsActive(true); }} >
            <LineAwesome icon={SvgBarsSolid} />
          </OverlayMenuButton>
        ) : (
          <div>
            <OverlayList>
              <OverlayListItem action onClick={() => { setIsActive(false); }}>
                <LineAwesome icon={SvgBarsSolid} /> Menu
              </OverlayListItem>
              {!window.isWebpackSingle ? (<OverlayListItem action onClick={() => { closeInspectionDetailModal(); setSelectedFile(null); setIsActive(false); navigate('/'); }}>
                <LineAwesome icon={SvgHomeSolid} /> Home
              </OverlayListItem>) : <></>}
              <OverlayListItem action onClick={handleOpenInspectionClick}>
                <LineAwesome icon={SvgExternalLinkAltSolid} /> Open Inspection
              </OverlayListItem>
            </OverlayList>
          </div>
        )}
      </OverlayContainer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="There is no inspection in this project."
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default NavOverlay;
