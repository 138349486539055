import { type PresetsType } from '@react-three/drei/helpers/environment-assets';
import React, { createContext, useContext, useState, type ReactNode, useEffect } from 'react';

interface CameraProviderProps {
  children: ReactNode;
}

interface CameraContextProps {
  isPerspective: boolean;
  isRefreshed: boolean;
  setPerspective: (bool: boolean) => void;
  setRefresh: (bool: boolean) => void;
  roomEnvironment: PresetsType;
  setRoomEnvironment: (environment: PresetsType) => void;
}

const CameraContext = createContext<CameraContextProps | undefined>(undefined);

export const CameraProvider: React.FC<CameraProviderProps> = ({ children }) => {
  const [isPerspective, setIsPerspective] = useState<boolean>(true);
  const [isRefreshed, setIsRefreshed] = useState<boolean>(false);

  const [roomEnvironment, setRoomEnvironment] = useState<PresetsType>(() => {
    const storedEnvironment = localStorage.getItem('roomEnvironment');
    return storedEnvironment ? JSON.parse(storedEnvironment) : 'warehouse';
  });

  useEffect(() => {
    localStorage.setItem('roomEnvironment', JSON.stringify(roomEnvironment));
  }, [roomEnvironment]);

  const setRefresh = (bool: boolean) => {
    setIsRefreshed(bool);
  };
  const setPerspective = (bool: boolean) => {
    setIsPerspective(bool);
  };

  return (
    <CameraContext.Provider value={{ roomEnvironment, setRoomEnvironment, isRefreshed, setRefresh, setPerspective, isPerspective }}>
      {children}
    </CameraContext.Provider>
  );
};

export const useCamera = () => {
  const context = useContext(CameraContext);
  if (!context) {
    throw new Error('useCamera must be used within a CameraProvider');
  }
  return context;
};
