interface ProjectInfo {
  name: string;
  date: string;
  previewImageBase64: string;
  fileUrl: string;
}

const indexedDBService = {
  db: null as IDBDatabase | null,
  dbName: 'projects-db',
  objectStoreName: 'projects',

  openDB: function (): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(this.objectStoreName, { keyPath: 'fileUrl' });
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        resolve();
      };

      request.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  },

  addProject: function (project: ProjectInfo): Promise<void> {
    const transaction = this.db.transaction([this.objectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.add(project);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },

  getProject: function (fileUrl: string): Promise<ProjectInfo | undefined> {
    const transaction = this.db.transaction([this.objectStoreName], 'readonly');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.get(fileUrl);

    return new Promise((resolve, reject) => {
      request.onsuccess = (event: Event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },

  getProjects: function (): Promise<ProjectInfo[]> {
    const transaction = this.db.transaction([this.objectStoreName], 'readonly');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = (event: Event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },

  clearAllProjects: function (): Promise<void> {
    const transaction = this.db.transaction([this.objectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.clear();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },

  deleteProject: function (fileUrl: string): Promise<void> {
    const transaction = this.db.transaction([this.objectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.delete(fileUrl);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },

  updateProject: function (project: ProjectInfo): Promise<void> {
    const transaction = this.db.transaction([this.objectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(this.objectStoreName);

    const request = objectStore.put(project);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  },
};


export default indexedDBService;
