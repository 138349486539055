import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import DashboardPage from '../pages/dashboard/dashboard';
import IndexPage from '../pages/model-viewer/model-viewer';
import { useSPProject } from '../providers/spproject';

import { useFileContext } from '../providers/file';
import { useProjects } from '../providers/projects';
import SettingsPage from '../pages/settings/settings';
import SPProject from '../modules/spproject/spproject';

const RootRouter: React.FC = () => {
  const { selectedFile, setSelectedFile } = useFileContext();
  const { loadModelAndInspection, loadModelSingleton, cleanUpProject } = useSPProject();
  const { projects, isLoading } = useProjects();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let projectName = '';

    if (path === '/') {
      updateDocumentTitle('Dashboard');
      setSelectedFile(null);
      cleanUpProject();
    } else if (path.startsWith('/model/')) {
      projectName = decodeURIComponent(path.substring('/model/'.length));
      updateDocumentTitle(projectName);

      if (!isLoading) {
        const project = projects.find((project) => project.name.replace('.spproject', '') === projectName.replace('.spproject', ''));

        if (project) {
          setSelectedFile(project.file);
          console.warn(`Project with name ${projectName} opened from cache.`);
        } else {
          console.warn(`Project with name ${projectName} not found.`);
          if (!selectedFile) {
            navigate('/');
          }
        }
      }
    } else if (path === '/settings') {
      updateDocumentTitle('Settings');
    }
  }, [location, projects]);

  useEffect(() => {
    const checkFileAndNavigate = async () => {
      if (selectedFile) {
        try {
          const isEncrypted = await SPProject.checkIfEncrypted(selectedFile);
          if (!isEncrypted) {
            navigateToModelPage(selectedFile);
          }
        } catch (error) {
          console.error('Error checking if file is encrypted:', error);
        }
      }
    };

    checkFileAndNavigate();
  }, [selectedFile]);

  useEffect(() => {
    if (window.isWebpackSingle) {
      loadModelSingleton();
    } else {
      loadModelAndInspection(selectedFile);
    }
  }, [selectedFile]);

  const navigateToModelPage = (file: File) => {
    navigate(`/model/${file.name.replace('.spproject', '')}`);
    updateDocumentTitle(file.name.replace('.spproject', ''));
  };

  const updateDocumentTitle = (title: string) => {
    document.title = `SuPAR - ${title}`;
  };

  if (isLoading) {
    return <div id="loader" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem', color: '#fafafa' }} />
    </div>;
  }

  function getRoutes() {
    if (window.isWebpackSingle) {
      console.log(window.isWebpackSingle);
      return (
        <Route path="*" element={<IndexPage />} />
      );
    } else {
      return (
        <>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<DashboardPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/model/:projectName" element={<IndexPage />} />
        </>
      );
    }
  }

  return (
    <Routes>
      {getRoutes()}
    </Routes>
  );
};

export default RootRouter;
