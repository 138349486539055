import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useSPProject } from '../../../providers/spproject';
import { type InspectionTypes } from '../../../modules/spproject/types';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs';
import * as pdfjs from 'pdfjs-dist';
import ImageViewer from 'awesome-image-viewer';

import pdfpng from '../../../assets/pdf.png';

import './inspection-detail.css';
import { Pagination } from '@mui/material';
import { LineAwesome, SvgAngleDownSolid, SvgCheckSolid, SvgCogSolid, SvgCommentsSolid, SvgExclamationSolid, SvgSmsSolid, SvgTimesSolid } from 'react-line-awesome-svg';

const InspectionDetailModal: React.FC = () => {
  const { resources, selectedPOI, stepIndex, setStepIndex, setSelectedPOI } = useSPProject();

  const color: string = colors[selectedPOI?.steps?.[stepIndex]?.noticeType];

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [images, setImages] = useState<{
    mainUrl: string;
    thumbnailUrl: string;
    description: string;
  }[]>(null);

  const [pdfs, setPdfs] = useState<{
    mainUrl: string;
    thumbnailUrl: string;
    description: string;
  }[]>(null);

  const [page, setPage] = useState(stepIndex + 1);

  const handleChange = (_, value) => {
    setPage(value);
    setStepIndex(value - 1);
  };

  async function _init() {
    if (resources) {
      const imageResources = resources.filter(resource => resource.type === 'Image');
      const pdfResources = resources.filter(resource => resource.type === 'Pdf');

      setImages(imageResources.map((resource, index) => ({
        mainUrl: resource.resourceUrl,
        thumbnailUrl: resource.resourceUrl,
        description: `Image ${index + 1} - ${resource.name}`,
      })));

      const pdfPreviews = await Promise.all(pdfResources.map(async (resource, pdfIndex) => {
        const pdfPreview = await getPDFPreview(resource.resourceUrl);

        if (pdfPreview) {
          return {
            mainUrl: resource.resourceUrl,
            thumbnailUrl: pdfPreview,
            description: `PDF Preview ${pdfIndex + 1} - ${resource.name}`,
          };
        } else {
          return null;
        }
      }));

      setPdfs(pdfPreviews);
    }
  }

  useEffect(() => {
    _init();
  }, [resources, selectedPOI, stepIndex]);

  function openGallery(index: number) {
    new ImageViewer({
      currentSelected: index,
      images: images,
    });
  }

  return (
    <Modal size="lg" data-backdrop="false" className="inspection-detail-modal" show={selectedPOI != null} onHide={() => { setSelectedPOI(null); }} backdrop={false} keyboard={false} >
      <div className='inspection-detail-modal-content' style={{ maxHeight: collapsed ? '62px' : 'calc(100vh - 150px)' }}>
        <div className='inspection-detail-modal-left-side' style={{ backgroundColor: color }}>
          <div className="node-leading" style={{ backgroundColor: 'transparent', marginBottom: '0px' }}>{selectedPOI?.order ?? 0}</div>
          {!collapsed ? <div>
            <div className="node-leading" style={{ backgroundColor: selectedPOI?.steps?.[stepIndex]?.noticeType == 'Ok' ? '#ffffff3d' : 'transparent' }}><LineAwesome icon={SvgCheckSolid} fill='white' /></div>
            <div className="node-leading" style={{ backgroundColor: selectedPOI?.steps?.[stepIndex]?.noticeType == 'Warning' ? '#ffffff3d' : 'transparent' }}><LineAwesome icon={SvgExclamationSolid} fill='white' /></div>
            <div className="node-leading" style={{ backgroundColor: selectedPOI?.steps?.[stepIndex]?.noticeType == 'Error' ? '#ffffff3d' : 'transparent' }}><LineAwesome icon={SvgTimesSolid} fill='white' /></div>
          </div> : <></>}
        </div>
        <div className='inspection-detail-modal-right-side'>
          <div className="modal-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px 32px 24px 32px' }}>
            <h5 className="modal-title " style={{ lineHeight: '2 !important' }} >{selectedPOI?.name ?? '-'}</h5>
            <button type="button" className='collapse-button' onClick={() => { if (collapsed) { setCollapsed(false); } else { setCollapsed(true); } }}>
              <LineAwesome icon={SvgAngleDownSolid} fill='white' style={{ fontSize: '30px', fill: '#f5f5f5', transform: collapsed ? 'rotate(180deg)' : '' }} />
            </button>
            <button type="button" className="close" onClick={() => { setSelectedPOI(null); }} >
              <LineAwesome icon={SvgTimesSolid} fill='white' style={{ fontSize: '30px', fill: '#f5f5f5' }} />
            </button>
          </div>
          <Modal.Body>
            <div className='d-flex align-items-center mb-3' style={{ color: '#f5f5f5' }}>
              <LineAwesome icon={SvgCogSolid} className="mr-3" fill='white' style={{ fontSize: '25px', fill: '#9e9e9e' }} />
              <div> <p className='mb-0' style={{ color: '#9e9e9e' }}>Part</p> <p className='mb-0'> {selectedPOI?.partId ?? '-'}</p></div>
            </div>
            <div className='d-flex align-items-center mb-3' style={{ color: '#f5f5f5' }}>
              <LineAwesome icon={SvgSmsSolid} className="mr-3" fill='white' style={{ fontSize: '25px', fill: '#9e9e9e' }} />
              <div> <p className='mb-0' style={{ color: '#9e9e9e' }}>Description</p> <p className='mb-0'>  {selectedPOI?.steps[stepIndex]?.description ?? '-'}</p></div>
            </div>
            <div className='d-flex align-items-center mb-3' style={{ color: '#f5f5f5' }}>
              <LineAwesome icon={SvgCommentsSolid} className="mr-3" fill='white' style={{ fontSize: '25px', fill: '#9e9e9e' }} />
              <div> <p className='mb-0' style={{ color: '#9e9e9e' }}>Comment</p> <p className='mb-0'> {selectedPOI?.steps[stepIndex]?.comment ?? '-'}</p></div>
            </div>
            <div className='resources-container ps-3 pe-3' style={{ maxHeight: pdfs && images && (pdfs.length > 0 || images.length > 0) ? '162px' : '0px' }}>
              {pdfs && pdfs.length > 0 && pdfs.map((pdf, index) => (
                <a key={index} target="_blank" href={pdf.mainUrl} className="node-image img-thumbnail" rel="noreferrer" style={{ backgroundImage: `url(${pdf.thumbnailUrl})` }}>
                  <div className='img-info'><img src={pdfpng} width='30px' /></div>
                </a>
              ))}
              {images && images.length > 0 && images.map((image, index) => (
                <a onClick={() => { openGallery(index); }} key={index} target="_blank" className="node-image img-thumbnail" rel="noreferrer" style={{ backgroundImage: `url(${image.thumbnailUrl})` }} />
              ))}
            </div>
            {selectedPOI && selectedPOI.steps.length > 1 && (
              <Pagination className='mt-2' count={selectedPOI?.steps.length} page={page} onChange={(_, value) => handleChange(_, value)} />
            )}
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

const colors: Record<InspectionTypes.NoticeType, string> = {
  NotChecked: '#808080',
  Ok: '#087f23',
  Warning: '#ffbb00',
  Error: '#ba000d',
};

async function getPDFPreview(url: string): Promise<string | null> {
  try {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const loadingTask = pdfjs.getDocument(url);
    const pdfDocument = await loadingTask.promise;

    const firstPage = await pdfDocument.getPage(1);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const viewport = firstPage.getViewport({ scale: 1 });

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await firstPage.render(renderContext).promise;

    const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob((blob) => resolve(blob)));

    if (!blob) {
      console.error('Error creating blob from canvas');
      return null;
    }

    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  } catch (error) {
    console.error('Error getting PDF preview:', error);
    return null;
  }
}
export default InspectionDetailModal;