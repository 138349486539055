import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

import { LineGeometry, LineMaterial, Line2 } from 'three-stdlib';
import Helper from '../modules/helper';
import Constant from '../modules/spproject/constant';
import { type InspectionTypes } from '../modules/spproject/types';
import { useSPProject } from '../providers/spproject';

const DrawLines: React.FC = () => {
  const [drawLinesGroup, setDrawLinesGroup] = useState<THREE.Group | null>(null);
  const { selectedPOI, stepIndex } = useSPProject();

  useEffect(() => {
    drawLinesGroup?.clear();

    if (selectedPOI && drawLinesGroup) {
      drawLines(selectedPOI?.steps[stepIndex].drawings ?? [], drawLinesGroup);
    }
  }, [selectedPOI, drawLinesGroup, stepIndex]);

  return <group ref={(group) => setDrawLinesGroup(group)} />;
};

export default DrawLines;

function drawLines(drawings: InspectionTypes.Drawing[], group: THREE.Group | null) {
  for (const drawing of drawings ?? []) {
    const centerPosition = transformPosition(new THREE.Vector3(drawing.position.x, drawing.position.y, drawing.position.z));

    const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);

    const pts: number[] = [];

    for (const point of drawing.points) {
      const pt = transformPosition(new THREE.Vector3(point.x, point.y, point.z));
      pts.push(pt.x, pt.y, pt.z);
    }

    const material = new LineMaterial({
      color: new THREE.Color(Helper.convertColor(drawing.color)).getHex(),
      resolution,
      linewidth: drawing.width,
      depthWrite: false,
      depthTest: true,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -4,
      polygonOffsetUnits: -1,
    });

    const geometry = new LineGeometry();
    geometry.setPositions(pts);

    const line = new Line2(geometry, material);
    line.computeLineDistances();

    const lineGroup = new THREE.Group();
    lineGroup.position.set(centerPosition.x, centerPosition.y, centerPosition.z);
    lineGroup.add(line);
    lineGroup.userData = { tag: Constant.ObjectTag.drawingLine, id: drawing.id };

    group?.add(lineGroup);
  }
}

export function transformPosition(p: THREE.Vector3): THREE.Vector3 {
  p.x = -p.x;

  return p;
}
